<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <div class="row">
            <div class="col-12 col-lg-10 m-auto">
              <div class="card">
                <div class="card-header">
                  <div class="row border-bottom border-dark">
                    <div class="col-lg-6 col-lx-6 col-md-12"><h4 class="mb-0">
                      <i class="fa fa-plus-square me-2"></i>Editar contrato
                    </h4></div>
                    <div class="col-lg-6 col-lx-6 col-md-12">
                      <div
                        class="border-radius-lg"
                      >
                        <div class="multisteps-form__progress">
                          <button
                            class="multisteps-form__progress-btn text-dark"
                            type="button"
                            title="Address"
                            :class="activeStep === 0 ? activeClass : ''"
                            @click="activeStep = 0"
                          >
                            1
                          </button>
                          <button
                            class="multisteps-form__progress-btn text-dark"
                            type="button"
                            title="Profile"
                            :class="activeStep === 1 ? activeClass : ''"
                            @click="activeStep = 1"
                          >
                            2
                          </button>
                          <button
                            class="multisteps-form__progress-btn text-dark"
                            type="button"
                            title="Profile"
                            :class="activeStep === 2 ? activeClass : ''"
                            @click="activeStep = 2"
                          >
                            3
                          </button>
                          <button
                            class="multisteps-form__progress-btn text-dark"
                            type="button"
                            title="Profile"
                            :class="activeStep === 3 ? activeClass : ''"
                            @click="activeStep = 3"
                          >
                            4
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="border-radius-xl bg-white" :class="activeStep === 0?'':'d-none'">
                    <div class="row">
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Sucursal<span class="text-danger ms-1">*</span> </label>
                        <select v-model="sucursal"
                                :disabled="deshabilitar_sucursal && this.$store.rol==='Super Administrador'"
                                :class="error_sucursal?'is-invalido':''"
                                class="mb-3 dropdown form-control" disabled>
                          <option selected disabled value="0">Seleccione una Sucursal</option>
                          <option v-for="item in allSucursales" :value="item.id" v-show="item.isActive">{{ item.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Agente<span class="text-danger ms-1">*</span></label>
                        <input class="form-control mb-3" :value="this.$store.state.username"
                               disabled :class="error_agente?'is-invalido':''">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Canal de venta<span class="text-danger ms-1">*</span></label>
                        <select v-model="canal"
                                :disabled="deshabilitar_canal"
                                :class="[{'form-control':deshabilitar_canal},{'dropdown':!deshabilitar_canal},{'is-invalido':error_canal}]"
                                class="form-control mb-3">
                          <option selected disabled value="">Seleccione una canal de venta</option>
                          <option value="portal">Portal web</option>
                          <option value="admin">Administración</option>
                        </select>
                      </div>
                      <h6 class="text-bold mb-1 mt-1">Datos del cliente</h6>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Nombre<span class="text-danger ms-1">*</span></label>
                        <div class="input-container">
                          <input type="text" v-model="nombre" :class="error_nombre?'is-invalido':''"
                                 @input="autocomplete" class="form-control dropdown mb-3">
                          <span class="clear-icon" @click="Clear_Data" v-show="nombre!==''">
                            <i class="fas fa-times text-danger"></i>
                          </span>
                        </div>
                        <ul v-if="showSubmenu" class="position-absolute z-index2 bg-light">
                          <li class="dropdown-item cursor-pointer d-block" v-for="item in autocompleteItems"
                              :key="item.id"
                              @click="selectItem(item)">{{ item.nombre }} {{ item.apellidos }}
                          </li>
                        </ul>
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Apellidos<span class="text-danger ms-1">*</span></label>
                        <input :disabled="deshabilitado" :class="error_apellidos?'is-invalido':''"
                               class="form-control mb-3" type="text" v-model="apellidos">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Tipo de cliente<span class="text-danger ms-1">*</span></label>
                        <select :class="error_tipo?'is-invalido':''"
                                class="form-control dropdown mb-3" v-model="tipo_cliente">
                          <option :value="null" selected disabled>Seleccione un tipo de cliente</option>
                          <option v-for="tipo in allTipocliente" :key="tipo.id" :value="tipo">{{ tipo.name }}</option>
                        </select>
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Edad<span class="text-danger ms-1">*</span></label>
                        <input type="text"
                               class="form-control mb-3"
                               v-model="edad" :class="error_edad?'is-invalido':''">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Correo<span class="text-danger ms-1">*</span></label>
                        <input type="email" autocomplete="new-password" v-model="email"
                               :class="error_email?'is-invalido':''"
                               class="form-control mb-3">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Teléfono<span class="text-danger ms-1">*</span></label>
                        <MazPhoneNumberInput
                          v-model="phone"
                          v-model:country-code="cod_telefono"
                          show-code-on-list
                          :error="error_phone"
                          :preferred-countries="['MX', 'CU', 'US']"
                          no-radius
                          @update="phone_result = $event"
                          noBorder="false"
                          :translations="{
                            countrySelector: {
                              placeholder: 'Código de país',
                              error: 'Seleccione un país',
                              searchPlaceholder: 'Buscar un país',
                            },
                            phoneInput: {
                              placeholder: 'Número de teléfono',
                              example: 'Ejemplo:',
                            },
                          }"
                        />
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Teléfono de emergencia<span class="text-danger ms-1">*</span></label>
                        <MazPhoneNumberInput
                          v-model="emergency_phone"
                          show-code-on-list
                          :error="error_emergency_phone"
                          :preferred-countries="['MX', 'CU', 'US']"
                          no-radius
                          @update="phoneEmergency_result = $event"
                          noBorder="false"
                          :translations="{
                            countrySelector: {
                              placeholder: 'Código de país',
                              error: 'Seleccione un país',
                              searchPlaceholder: 'Buscar un país',
                            },
                            phoneInput: {
                              placeholder: 'Número de teléfono',
                              example: 'Ejemplo:',
                            },
                          }"
                        />
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Dirección<span class="text-danger ms-1">*</span></label>
                        <textarea :class="error_direccion?'is-invalido':''"
                                  class="form-control mb-3" v-model="direccion"></textarea>
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Código postal<span class="text-danger ms-1">*</span></label>
                        <input :class="error_postal?'is-invalido':''"
                               class="form-control mb-3" type="text" v-model="postal" maxlength="5">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">País<span class="text-danger ms-1">*</span></label>
                        <input :class="error_pais?'is-invalido':''"
                               v-model="pais" class="form-control dropdown mb-3" type="text">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Estado<span class="text-danger ms-1">*</span></label>
                        <input :class="error_estado?'is-invalido':''"
                               v-model="estado" class="form-control dropdown mb-3" type="text">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Ciudad<span class="text-danger ms-1">*</span></label>
                        <input type="text" v-model="ciudad"
                               :class="error_ciudad?'is-invalido':''"
                               class="form-control mb-3">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Salida<span class="text-danger ms-1">*</span></label>
                        <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                                       placeholder="Seleccione una fecha" :class="error_salida?'error_fecha':''"
                                       minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                                       v-model="salida" class="form_fecha mb-3" format="dd/MM/yyyy hh:mm aa">
                          <template #minutes="{ text, value }">
                            00
                          </template>
                        </VueDatePicker>
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Regreso<span class="text-danger ms-1">*</span></label>
                        <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                                       placeholder="Seleccione una fecha" :class="error_regreso?'error_fecha':''"
                                       minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                                       v-model="regreso" class="form_fecha mb-3" format="dd/MM/yyyy hh:mm aa">
                          <template #minutes="{ text, value }">
                            00
                          </template>
                        </VueDatePicker>
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Días contratados<span class="text-danger ms-1">*</span></label>
                        <input class="form-control border border-secondary p-2 mb-3" min="0" readonly type="number"
                               v-model="dias">
                      </div>
                    </div>
                    <div class="button-row d-flex mt-4">
                      <div class="col-6">
                        <material-button
                          type="button"
                          color="secondary"
                          variant="gradient"
                          class="ms-auto"
                          @click="Regresar()"
                        >Cancelar
                        </material-button
                        >
                      </div>
                      <div class="col-6 text-end">
                        <material-button
                          type="button"
                          color="dark"
                          variant="gradient"
                          class="ms-auto js-btn-next"
                          @click="Next_Dos()"
                        >Siguiente
                        </material-button
                        >
                      </div>
                    </div>
                  </div>
                  <div class="border-radius-xl bg-white" :class="activeStep === 1?'':'d-none'">
                    <div class="multisteps-form__content">
                      <div class="row mb-2 services_extras_container">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 services_extras mb-2 shadow rounded"
                             v-for="item in elementosPaginaActual" :key="item.id">
                          <div class="d-flex align-items-center">
                            <img :src="getURL(item.icono)" class="img-fluid me-3" style="height: 35px; width: 35px">
                            <span>{{ item.name }}</span>
                          </div>
                          <div class="d-flex align-items-center">
                            <a v-if="servicios_reservados.includes(item.id)" class="btn btn-primary1 mb-0"
                               style="width: 315px"
                               @click="this.Quitar(item.id, item.precio, item.diario, item.name)">
                              <i class="fa fa-minus-square"></i> Quitar<span
                              class="ms-2">$ {{ item.precio }} MXN</span>
                              <span v-if="item.diario" class="ms-1">/ día</span>
                              <span v-else class="ms-1">/ único pago</span>
                            </a>
                            <a v-else class="btn btn-issy mb-0"
                               style="width: 315px"
                               @click="this.Adicionar(item.id, item.precio, item.diario, item.name)">
                              <i class="fa fa-plus-square"></i> Adicionar<span
                              class="ms-2">$ {{ item.precio }} MXN</span>
                              <span v-if="item.diario" class="ms-1">/ día</span>
                              <span v-else class="ms-1">/ único pago</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <button @click="paginaAnterior" class="btn btn-dark me-3"><i class="material-icons opacity-10">arrow_back_ios</i>
                        </button>
                        <button v-for="index in totalPaginas" :key="index" @click="irPaginaActual(index)"
                                class="btn me-3" :class="index===paginaActual?'btn-info':'btn-secondary'">{{ index }}
                        </button>
                        <button @click="paginaSiguiente" class="btn btn-dark"><i
                          class="material-icons opacity-10">arrow_forward_ios</i></button>
                      </div>
                      <div class="row">
                        <div
                          class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ps-2 pe-2 mb-2 shadow rounded">
                          <h5 class="text-center">Otro servicio</h5>
                          <div class="row">
                            <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                              <label class="form-label">Descripción</label>
                              <input type="text" class="form-control p-2 border border-secondary"
                                     v-model="description_extra_service" :disabled="!extra_service_add">
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                              <label class="form-label">Precio</label>
                              <input type="number" class="form-control p-2 border border-secondary"
                                     v-model="precio_otro" :disabled="!extra_service_add">
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                              <div class="form-check p-0">
                                <input :disabled="!extra_service_add"
                                       class="form-check-input"
                                       type="checkbox"
                                       id="pago_diario"
                                       v-model="diario_otro"
                                />
                                <label for="pago_diario" class="h6 text-bold mb-1">Pago diario</label>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                              <div class="d-flex align-items-center">
                                <a v-if="extra_service_add" :id="'adicionar'+'otro'" class="btn w-100 btn-issy mb-0"
                                   @click="Adicionar('otro', precio_otro, diario_otro, description_extra_service)">
                                  <i class="fa fa-plus-square"></i> Adicionar<span
                                  class="ms-2">$ {{ precio_otro }} MXN</span>
                                  <span class="ms-1">/ {{ diario_otro ? "Día" : "Único pago" }}</span>
                                </a>
                                <a v-else :id="'quitar'+'otro'" class="btn w-100 btn-primary1 mb-0"
                                   @click="Quitar('otro', precio_otro, diario_otro, description_extra_service)">
                                  <i class="fa fa-minus-square"></i> Quitar<span
                                  class="ms-2">$ {{ precio_otro }} MXN</span>
                                  <span class="ms-1">/ {{ diario_otro ? "Día" : "Único pago" }}</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="button-row d-flex mt-4">
                        <div class="col-6">
                          <material-button
                            type="button"
                            color="secondary"
                            variant="gradient"
                            class="ms-auto"
                            @click="prevStep()"
                          >Regresar
                          </material-button
                          >
                        </div>
                        <div class="col-6 text-end">
                          <material-button
                            type="button"
                            color="dark"
                            variant="gradient"
                            class="ms-auto js-btn-next"
                            @click="Next_Cuatro"
                          >Siguiente
                          </material-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="border-radius-xl bg-white" :class="activeStep === 2?'':'d-none'">
                    <div class="row">
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">No. de licencia<span class="text-danger ms-1">*</span></label>
                        <input :disabled="deshabilitado_licencia" maxlength="15"
                               :class="error_licencia?'is-invalido':''"
                               class="form-control mb-3" type="text" v-model="licencia">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12" v-show="!this.licencia_permanente">
                        <label class="form-label">Expiración de la licencia<span
                          class="text-danger ms-1">*</span></label>
                        <VueDatePicker v-model="exp_licencia" :class="error_lic_exp?'error_fecha':''"
                                       format="dd/MM/yyyy"
                                       placeholder="Seleccione una fecha" class="form_fecha" locale="es"
                                       auto-apply />
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Trámite de la licencia (País)<span
                          class="text-danger ms-1">*</span></label>
                        <input :disabled="deshabilitado_lic_pais"
                               :class="error_lic_pais?'is-invalido':''" v-model="paisLicencia"
                               class="form-control mb-3">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Trámite de la licencia (Estado)<span
                          class="text-danger ms-1">*</span></label>
                        <input :disabled="deshabilitado_lic_estado"
                               :class="error_lic_estado?'is-invalido':''" v-model="estado_licencia"
                               class="form-control mb-3">
                      </div>
                      <div class="col-lg-12 col-lx-12 col-sm-12 col-md-12 d-flex justify-content-start">
                        <div class="form-check p-0">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="conductor_extra"
                            v-model="licencia_permanente"
                          />
                          <label for="conductor_extra" class="">Licencia permanente</label>
                        </div>
                      </div>
                    </div>
                    <div v-show="conductor_extra" class="row">
                      <h6 class="text-bold mb-1 mt-1">Conductor extra</h6>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Nombre</label>
                        <input :disabled="deshabilitar_extra"
                               class="form-control dropdown mb-3"
                               type="text"
                               v-model="extra_nombre" :class="error_extra_nombre?'is-invalido':''"
                               @input="autocompleteConductor">
                        <ul v-if="showSubmenuConductor" class="position-absolute z-index2 bg-light">
                          <li class="dropdown-item cursor-pointer d-block" v-for="item in autocompleteItemsConductor"
                              :key="item.id"
                              @click="selectItemConductor(item)">{{ item.nombre }} {{ item.apellidos }}
                          </li>
                        </ul>
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Apellidos</label>
                        <input class="form-control mb-3" type="text" :class="error_extra_apellidos?'is-invalido':''"
                               v-model="extra_apellidos" :disabled="deshabilitar_extra">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Edad</label>
                        <input class="form-control mb-3" min="18" type="number"
                               :class="error_extra_edad?'is-invalido':''"
                               v-model="extra_edad" :disabled="deshabilitar_extra">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">No. de licencia</label>
                        <input class="form-control mb-3" type="text" :class="error_extra_licencia?'is-invalido':''"
                               v-model="extra_licencia">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12" v-show="!this.licencia_permanente_extra">
                        <label class="form-label">Expiración de la licencia</label>
                        <VueDatePicker v-model="extra_exp_licencia"
                                       format="MM/yyyy"
                                       placeholder="Seleccione una fecha" class="form_fecha" locale="es"
                                       auto-apply />
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Trámite de la licencia (Estado)<span
                          class="text-danger ms-1">*</span></label>
                        <input
                          :class="error_extra_estado?'is-invalido':''" v-model="extra_estado"
                          class="form-control mb-3">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Trámite de la licencia (País)<span
                          class="text-danger ms-1">*</span></label>
                        <input
                          :class="error_extra_pais?'is-invalido':''" v-model="extra_pais"
                          class="form-control mb-3">
                      </div>
                      <div
                        class="col-lg-12 col-lx-12 col-sm-12 col-md-12 d-flex align-items-center justify-content-start">
                        <div class="form-check p-0">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="conductor_extra"
                            v-model="licencia_permanente_extra"
                          />
                          <label for="conductor_extra" class="">Licencia permanente</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="text-bold mb-1 mt-1">Información del vehículo</h6>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Categoría<span
                          class="text-danger ms-1">*</span></label>
                        <select v-model="categoria" class="form-control dropdown mb-3"
                                :class="error_categoria?'is-invalido':''"
                                :disabled="deshabilitar_categoria">
                          <option disabled :value="null">Seleccione una categoria</option>
                          <option selected :value="categoria_id">{{ categoria_id.name }}</option>
                          <option v-for="item in allCategoriasAdmin" :key="item.id" :value="item"
                                  v-show="item.isActive"
                                  v-bind:class="categorias_id.includes(item.id)?'':'text-danger'">
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-lg-4 col-lx-4 col-md-12">

                      </div>
                      <div class="col-lg-4 col-lx-4 col-md-12">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Número económico<span
                          class="text-danger ms-1">*</span></label>
                        <select class="form-control dropdown mb-3" v-model="vehiculo"
                                :class="error_vehiculo?'is-invalido':''">
                          <option disabled value="0">Seleccione un vehículo</option>
                          <option :key="vehiculo_reservado.id"
                                  :value="vehiculo_reservado.id">{{ vehiculo_reservado.noEcon }}
                          </option>
                          <option v-for="item in vehiculosPerName" :key="item.id"
                                  v-show="item.categoria.id===categoria.id && item.isActive"
                                  :value="item.id">{{ item.noEcon }}
                          </option>
                        </select>
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Placa<span class="text-danger ms-1">*</span></label>
                        <input type="text" disabled class="form-control" :value="placa">
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Marca<span class="text-danger ms-1">*</span></label>
                        <input class="form-control mb-3 p-2 border border-secondary" v-model="marca" disabled>
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Modelo<span class="text-danger ms-1">*</span></label>
                        <input class="form-control mb-3 p-2 border border-secondary" v-model="modelo" disabled>
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Color<span class="text-danger ms-1">*</span></label>
                        <input class="form-control mb-3 p-2 border border-secondary" v-model="color" disabled>
                      </div>
                      <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                        <label class="form-label">Cantidad del tanque(Litros)<span
                          class="text-danger ms-1">*</span></label>
                        <input class="form-control border border-secondary p-2 mb-3" v-model="tanque" disabled>
                      </div>
                    </div>
                    <div class="button-row d-flex mt-4">
                      <div class="col-6">
                        <material-button
                          type="button"
                          color="secondary"
                          variant="gradient"
                          class="ms-auto"
                          @click="prevStep()"
                        >Regresar
                        </material-button
                        >
                      </div>
                      <div class="col-6 text-end">
                        <material-button
                          type="button"
                          color="dark"
                          variant="gradient"
                          class="ms-auto js-btn-next"
                          @click="Next_Tres()"
                        >Siguiente
                        </material-button
                        >
                      </div>
                    </div>
                  </div>
                  <div class="border-radius-xl bg-white" :class="activeStep === 3?'':'d-none'">
                    <div class="multisteps-form__content">
                      <div class="row mb-2">
                        <h6 class="text-bold mb-1">Tarifas</h6>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Tipo de cobertura<span class="text-danger ms-1">*</span></label>
                          <select v-model="cobertura" class="form-control mb-3 dropdown"
                                  :class="cobertura===''?'is-invalid text-input-uv':''">
                            <option value="total">Cobertura total</option>
                            <option value="tradicional">Cobertura tradicional</option>
                          </select>
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Tarifa diaria<span class="text-danger ms-1">*</span></label>
                          <input v-model.lazy="tarifa_diaria" v-money="money" class="form-control"
                                 :class="error_tarifa_diaria?'is-invalido':''" :readonly="canal === 'portal'"
                                 :min="cobertura==='total'?this.tarifa_total:this.tarifa_tradicional" />
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Tarifa servicios extras</label>
                          <input class="form-control mb-3" min="0"
                                 v-model.lazy="tarifa_extra" v-money="money" readonly>
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Tarifa de hora extra<span class="text-danger ms-1">*</span></label>
                          <input class="form-control mb-3" readonly
                                 :class="error_tarifa_hora_extra?'is-invalido':''"
                                 v-model.lazy="tarifa_hora_extra" v-money="money">
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Servicios extras contratados</label>
                          <ul>
                            <li v-for="item in servicios_contratados">{{ item }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12 d-flex justify-content-between">
                          <div class="form-check p-0">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="tanke_lleno"
                              v-model="tanque_lleno"
                            />
                            <label for="tanke_lleno" class="h6 text-bold mb-1"> Tanque prepagado
                            </label>
                          </div>
                        </div>
                        <div v-if="tanque_lleno" class="row">
                          <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                            <label class="form-label">Precio litro</label>
                            <input class="form-control mb-3" readonly
                                   v-model.lazy="precio_gasolina" v-money="money">
                          </div>
                          <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                            <label class="form-label">Total</label>
                            <input class="form-control mb-3" readonly
                                   v-model.lazy="total" v-money="money">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Total a pagar<span class="text-danger ms-1">*</span></label>
                          <input class="form-control mb-3"
                                 v-model.lazy="total_pagado" v-money="money"
                                 readonly>
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Total de renta pagado<span class="text-danger ms-1">*</span></label>
                          <input class="form-control mb-3"
                                 v-model.lazy="monto_acreditado" v-money="money"
                                 readonly>
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Pago pendiente<span class="text-danger ms-1">*</span></label>
                          <input class="form-control mb-3"
                                 v-model.lazy="pago_pendiente" v-money="money"
                                 readonly>
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Aplicar cortesía (Opcional)<span
                            class="text-danger ms-1">*</span></label>
                          <select class="form-select" v-model="cortesia">
                            <option :value="0">Sin descuento</option>
                            <option v-for="item in cortesias" :key="item.id" v-show="item.isActive" :value="item.id">
                              {{ item.name }}
                              {{ item.descuento }} %
                            </option>
                          </select>
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Monto de cortesía<span class="text-danger ms-1">*</span></label>
                          <input class="form-control mb-3"
                                 v-model.lazy="descuento_cortesia" v-money="money"
                                 readonly>
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Importe a pagar<span class="text-danger ms-1">*</span></label>
                          <input class="form-control mb-3"
                                 v-model.lazy="total_contrato" v-money="money"
                                 readonly>
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Forma de pago<span class="text-danger ms-1">*</span></label>
                          <select class="form-control mb-3" v-model="tipo_pago">
                            <option value="tarjeta">Tarjeta</option>
                            <option value="efectivo">Efectivo</option>
                          </select>
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label v-if="tipo_pago==='efectivo'" class="form-label">Pagado en efectivo</label>
                          <label v-else class="form-label">Pagado con tarjeta</label>
                          <input class="form-control mb-3" v-money="money"
                                 :class="error_deposito_efectivo?'is-invalido':''"
                                 v-model.lazy="deposito">
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Check in</label>
                          <input class="form-control mb-3" v-model.lazy="check" v-money="money">
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Tarjeta de crédito<span class="text-danger ms-1">*</span></label>
                          <input id="tarjeta" class="form-control dropdown mb-3" type="text"
                                 :class="error_tarjeta?'is-invalido':''" v-model="tarjeta"
                                 maxlength="16">
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Vencimiento<span class="text-danger ms-1">*</span></label>
                          <VueDatePicker v-model="exp_tarjeta" :class="error_exp_tarjeta?'error_fecha':''" month-picker
                                         placeholder="Seleccione una fecha" class="form_fecha" locale="es"
                                         auto-apply />
                        </div>
                        <div class="col-lg-4 col-lx-4 col-sm-12 col-md-12">
                          <label class="form-label">Autorización<span class="text-danger ms-1">*</span></label>
                          <input type="text" class="form-control mb-3"
                                 v-model="tarjeta_autorizacion" maxlength="8"
                                 :class="error_tarjeta_autorizacion?'is-invalido':''">
                        </div>
                        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 d-flex justify-content-between mt-1">
                          <div class="form-check p-0">
                            <input
                              v-if="tienePermiso('asignar_credito')"
                              class="form-check-input"
                              type="checkbox"
                              id="conductor_extra"
                              v-model="credito"
                            />
                            <label for="conductor_extra" class="h6 text-bold mb-1">Crédito</label>
                          </div>
                        </div>
                        <div class="col-lg-6 col-lx-6 col-sm-12 col-md-12 d-flex justify-content-between mt-1">
                          <div class="form-check p-0">
                            <input
                              v-if="tienePermiso('asignar_credito')"
                              class="form-check-input"
                              type="checkbox"
                              id="conductor_extra"
                              v-model="pagar_diferencia"
                            />
                            <label for="conductor_extra" class="h6 text-bold mb-1">Pagado</label>
                          </div>
                        </div>
                        <div class="col-lg-12 col-lx-12 col-sm-12 col-md-12">
                          <label class="form-label">Comentarios</label>
                          <textarea class="form-control" v-model="comentarios"></textarea>
                        </div>

                      </div>

                      <div class="button-row d-flex mt-4">
                        <div class="col-6">
                          <material-button
                            type="button"
                            color="secondary"
                            variant="gradient"
                            class="ms-auto"
                            @click="prevStep()"
                          >Regresar
                          </material-button
                          >
                        </div>
                        <div class="col-6 text-end">
                          <material-button
                            type="button"
                            color="dark"
                            variant="gradient"
                            class="ms-auto js-btn-next"
                            @click="Guardar"
                          >Guardar
                          </material-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Seguridad">
    <div v-if="seguridad" :class="['modal', { 'show': seguridad }]" @transitionend="onTransitionEnd">
      <div class="modal-content1">
        <div class="row mb-3 border-bottom border-dark">
          <h6 class="text-start"><i class="fa fa-lock me-2"></i>Escriba su contraseña<i @click="closeSeguridad"
                                                                                        class="material-icons-round opacity-10 modal-icon">close</i>
          </h6>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <input v-model="password" type="password" autocomplete="new-password"
                   class="form-control p-2 border border-secondary">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe escribir su contraseña</p></div>
            </div>
            <div v-show="error_seguridad" class="text-danger mt-3 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Contraseña incorrecta</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeSeguridad" class="btn btn-secondary" type="button">Cerrar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="Comprobar" class="btn btn-dark" type="button">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import gql from "graphql-tag";
import Swal from "sweetalert2";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import noImagen from "@/assets/img/NoImage.jpg";
import { round } from "@popperjs/core/lib/utils/math";
import { VMoney } from "v-money";
import { decodeBase64 } from "@/util";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import {
  createCliente,
  createDriver,
  createExtraServiceToContract,
  createExtraServiceToReserve,
  createLicencia,
  createTarjeta, get_all_branch, get_all_categories, get_all_client_types,
  get_all_clients,
  get_all_offices, get_all_secures,
  get_all_users, get_car_by_categorie,
  get_Discounts,
  serviceByContract,
  servicesPerName,
  updateContract
} from "@/actions";

const ALLCATEGORIASADMIN = gql`
query CategoriasDisponiblesAdmin($fechaRenta:DateTime!, $fechaRegreso:DateTime!) {
    categoriasDisponiblesAdmin(fechaSalida: $fechaRenta, fechaRegreso: $fechaRegreso) {
        id
        name
        tradicionalAlta
        totalAlta
        tradicionalBaja
        totalBaja
        isActive
        fecha
        image
        iconoWhite
        iconoBlack
        maletas
        maletasSm
        personas
        transmision
        lugar
    }
}
`;
const GASOLINA = gql`
query Gasolina {
    gasolina
}`;
const CLIENTE_EMAIL = gql`
query ClientePerEmail($email:String!) {
    clientePerEmail(email: $email) {
        id
        nombre
        apellidos
        telefono
        email
        edad
        isActive
        direccion
        postal
        telefonoEmergencia
        carnet
        licenciaFile
        pasaporte
        tipo {
            id
            name
            isActive
        }
        ciudad
        estado
        pais
        licencia {
            id
            numero
            vencimiento
            permanente
            estado
            pais
        }
        tarjeta {
            id
            numero
            vencimiento
            autorizacion
        }
    }
}
`;
const VEHICULO_PER_ID = gql`
query VehiculoPerId($id:ID!) {
    vehiculoPerId(id: $id) {
        id
        noEcon
        categoria {
            id
            name
            tradicionalAlta
            totalAlta
            tradicionalBaja
            totalBaja
            isActive
            fecha
            image
            iconoWhite
            iconoBlack
            maletas
            maletasSm
            personas
            transmision
            lugar
        }
        version {
            id
            name
            isActive
            modelo {
              id
              name
              marca {
                id
                name
              }
            }
        }
        anno
        transmision
        vin
        titular {
            id
            name
            isActive
        }
        aseguradora {
            id
            name
            isActive
        }
        placa
        estado {
            id
            name
            abreviatura
            isActive
        }
        isActive
        numeroSerie
        color
        capacidadTanke
        fechaAlta
        sucursal {
            id
            name
            phone
            contactPhone
            address
            postalCod
            openHour
            closeHour
            isActive
        }
        estatus
        fechaCredito
        fechaCirculacion
        fechaPoliza
        files
    }
}
`;
const SEGURIDAD = gql`
mutation Login($email:String! $password:String!) {
    login(email: $email password: $password) {
        success
        token
        refreshToken
        error
    }
}`;
const DISPONIBLES = gql`
query AllDisponibles(
    $categoria: Int!
    $fechaSalida:DateTime!
    $fechaRegreso:DateTime!
) {
    vehiculosDisponibles(
        categoria: $categoria
        fechaSalida:$fechaSalida
        fechaRegreso:$fechaRegreso
        after:""
        before:""
        first:100
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noEcon
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                version {
                    id
                    name
                    isActive
                    modelo {
                      id
                      name
                      marca {
                        id
                        name
                      }
                    }
                }
                anno
                transmision
                vin
                titular {
                    id
                    name
                    isActive
                }
                aseguradora {
                    id
                    name
                    isActive
                }
                placa
                estado {
                    id
                    name
                    abreviatura
                    isActive
                }
                isActive
                numeroSerie
                color
                capacidadTanke
                fechaAlta
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                estatus
                fechaCredito
                fechaCirculacion
                fechaPoliza
                files
            }
        }
    }
}`;
const TEMPORADA = gql`
query TemporadaAlta($fechaInicio: Date!, $fechaFin: Date!) {
    temporadaAlta(fechaInicio: $fechaInicio, fechaFin: $fechaFin)
}`;
const CORTESIA = gql`
query CortesiaById($id:ID!) {
    cortesiaById(id: $id) {
        id
        name
        descuento
    }
}`;
export default {
  name: "New_Contrato",
  components: {
    MazPhoneNumberInput,
    VueDatePicker,
    MaterialButton
  },
  data() {
    return {
      desc_original_contract: 0,
      desc_reserva: false,
      services_add: {
        id: 0,
        precio: 0,
        diario: false,
        nombre: ""
      },
      services_calc: [],
      phone_result: {},
      phoneEmergency_result: {},
      cod_telefono: "",
      contrato: 0,
      servicios_contratados: [],
      description_extra_service: "",
      extra_service_add: true,
      error_extra_pais: false,
      extra_exp_licencia: null,
      extra_pais: "",
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
      pais: "",
      categoria_name: "",
      ciudad: "",
      estado: "",
      paisLicencia: "",
      estado_licencia: "",
      precio_otro: 0,
      categoria_id: null,
      inicio: true,
      porciento_cortesia: 0,
      diario_otro: false,
      serviciosPerName: [],
      vehiculo_contract: null,
      services: [],
      cortesias: [],
      allCategorias: [],
      categorias_id: [],
      cortesia: 0,
      descuento_cortesia: 0,
      tipo_pago: "tarjeta",
      Alta: false,
      extra_conductor_menor: false,

      gasolina: [],
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      allSucursales: [],
      allEstados: [],
      allUsers: [],
      allTipocliente: [],
      allCliente: [],
      ciudadPerNamee: [],
      autocompleteItems: [],
      autocompleteItemsConductor: [],
      nuevoCliente: null,
      showSubmenuConductor: false,

      //otras atributos
      showSubmenu: false,
      nuevaLicencia: null,
      nuevaTarjeta: null,
      cobertura: "total",


      //Pagina uno
      sucursal: 0,
      agente: 0,
      canal: "",
      cliente: null,
      nombre: "",
      apellidos: "",
      edad: 0,
      tipo_cliente: null,
      email: "",
      phone: "",
      emergency_phone: "",
      direccion: "",
      postal: "",
      error: false,
      error_seguridad: false,
      //pagina dos
      conductor_extra: false,
      allCategoriasAdmin: [],
      vehiculo_reservado: [],
      vehiculosPerName: [],
      allMarcas: [],
      modeloPerMarca: [],
      marcaCategoria: [],
      colores: [],
      filtroVehiculos: [],
      categoria: null,
      marca: 0,
      modelo: 0,
      color: "",
      tanque: 0,
      vehiculo: 0,
      exp_licencia: null,
      exp_tarjeta: null,
      exp_extra: null,
      licencia: "",
      licencia_permanente: false,
      estado_licencia_extra: "",
      licencia_extra: "",
      licencia_permanente_extra: false,
      tarjeta: "",
      tarjeta_autorizacion: "",
      extra_nombre: "",
      extra_apellidos: "",
      extra_edad: 0,
      extra_licencia: "",
      extra_estado: 0,

      //Pagina tres
      salida: null,
      regreso: null,
      allSeguros: null,
      tanque_lleno: false,
      tarifa_diaria: 0,
      tarifa_extra: 0,
      dias: 0,
      descripcion: "",
      seguro: null,
      seguro_precio: 0,
      seguro_total: 0,
      seguro_rc: false,
      total_pagado: 0,
      pago_pendiente: 0,
      deposito: 0,
      check: 0,
      credito: false,
      comentarios: "",
      precio_gasolina: 0,
      total: 0,
      tarifa_km_extra: 0,
      tarifa_hora_extra: 0,
      km_permitidos: 0,
      deshabilitado_lic_pais: false,
      error_lic_pais: false,
      cliente_cargado: null,
      tarifa_total: 0,
      tarifa_tradicional: 0,
      deshabilitado: false,
      deshabilitado_phone: false,
      deshabilitado_direccion: false,
      deshabilitado_estado: false,
      deshabilitado_ciudad: false,
      deshabilitado_postal: false,
      deshabilitado_licencia: false,
      deshabilitado_lic_exp: false,
      deshabilitado_lic_estado: false,
      deshabilitado_tarjeta: false,
      deshabilitado_tarj_exp: false,
      deshabilitado_tarj_aut: false,
      deshabilitado_tipo: false,
      deshabilitar_extra: false,
      deshabilitar_extra_licencia: false,
      deshabilitar_sucursal: false,
      deshabilitar_canal: false,
      deshabilitar_categoria: false,
      deshabilitado_nombre: false,
      seguridad: false,
      password: "",
      elementosPorPagina: 8,
      paginaActual: 1,
      totalPaginas: 0,
      servicios_reservados: [],
      reserva: null,
      array_servicios: [],
      renta_auto: 0,
      total_extras: 0,
      deshabilitado_tipo_pago: false,
      deshabilitar_servicios: false,
      monto_acreditado: 0,
      deshabilitado_pais: false,
      error_pais: false,
      error_sucursal: false,
      error_canal: false,
      error_agente: false,
      error_nombre: false,
      error_apellidos: false,
      error_edad: false,
      error_email: false,
      error_phone: false,
      error_tipo: false,
      error_emergency_phone: false,
      error_direccion: false,
      error_estado: false,
      error_ciudad: false,
      error_postal: false,
      error_salida: false,
      error_regreso: false,

      error_licencia: false,
      error_lic_exp: false,
      error_lic_estado: false,
      error_categoria: false,
      error_vehiculo: false,
      error_extra_nombre: false,
      error_extra_apellidos: false,
      error_extra_edad: false,
      error_extra_estado: false,
      error_exp_extra: false,
      error_extra_licencia: false,

      error_tarifa_diaria: false,
      error_tarifa_hora_extra: false,
      error_deposito_efectivo: false,
      error_tarjeta: false,
      error_exp_tarjeta: false,
      error_tarjeta_autorizacion: false,
      pagar_diferencia: true,
      monto_guardado: 0,
      placa: "",
      total_contrato: 0
    };
  },
  directives: { money: VMoney },
  methods: {
    get_Offices() {
      get_all_offices().then(resp => {
        this.allSucursales = resp.data.data.allSucursales;
      });
    },
    get_Discounts() {
      get_Discounts().then(resp => {
        this.cortesias = resp.data.data.cortesias;
      });
    },
    get_all_clients() {
      get_all_clients().then(resp => {
        this.allCliente = resp.data.data.allCliente;
      });
    },
    get_all_users() {
      get_all_users().then(resp => {
        this.allUsers = resp.data.data.allUsers;
      });
    },
    get_all_client_types() {
      get_all_client_types().then(resp => {
        this.allTipocliente = resp.data.data.allTipocliente;
      });
    },
    get_all_categories() {
      get_all_categories().then(resp => {
        this.allCategoriasAdmin = resp.data.data.allCategoriasAdmin;
      });
    },
    get_all_branch() {
      get_all_branch().then(resp => {
        this.allMarcas = resp.data.data.allMarcas;
      });
    },
    get_car_by_categorie() {
      get_car_by_categorie(this.categoria ? this.categoria.id : 0).then(resp => {
        this.filtroVehiculos = resp.data.data.filtroVehiculos;
      });
    },
    get_all_secures() {
      get_all_secures().then(resp => {
        this.allSeguros = resp.data.data.allSeguros;
      });
    },

    esHoy(item) {
      const fecha = new Date(item);
      const hoy = new Date();
      return fecha.getDate() === hoy.getDate() &&
        fecha.getMonth() === hoy.getMonth() &&
        fecha.getFullYear() === hoy.getFullYear();
    },
    isBase64(str) {
      return /[a-zA-Z]/.test(str);
    },
    autocomplete() {
      this.autocompleteItems = this.allCliente.filter(item =>
        item.nombre.toLowerCase().includes(this.nombre.toLowerCase())
      );
      this.showSubmenu = this.nombre !== "";
    },
    autocompleteConductor() {
      this.autocompleteItemsConductor = this.allCliente.filter(item =>
        item.nombre.toLowerCase().includes(this.extra_nombre.toLowerCase())
      );
      this.showSubmenuConductor = this.nombre !== "";
    },
    selectItemConductor(item) {
      this.deshabilitar_extra = true;
      this.extra_nombre = item.nombre;
      this.extra_apellidos = item.apellidos;
      this.extra_edad = item.edad;
      this.extra_estado = item.licencia ? item.licencia.estado : "";
      this.extra_pais = item.licencia ? item.licencia.pais : "";
      this.extra_licencia = item.licencia ? item.licencia.numero : "";
      this.exp_extra = item.licencia ? item.licencia.vencimiento : "";
      if (this.extra_licencia !== "") {
        this.deshabilitar_extra_licencia = true;
      }
      this.showSubmenuConductor = false;

    },
    selectItem(item) {
      this.deshabilitado = true;
      this.nombre = item.nombre;
      this.apellidos = item.apellidos;
      this.tipo_cliente = item.tipo ? item.tipo : 0;
      if (this.tipo_cliente !== 0) {
        this.deshabilitado_tipo = true;
      }
      this.edad = item.edad;
      this.email = item.email;
      this.phone = item.telefono;
      this.emergency_phone = item.telefonoEmergencia ? item.telefonoEmergencia : "";
      if (this.emergency_phone !== "") {
        this.deshabilitado_phone = true;
      }
      this.direccion = item.direccion ? item.direccion : "";
      if (this.direccion !== "") {
        this.deshabilitado_direccion = true;
      }
      this.estado = item.estado ? item.estado : "";
      if (this.estado !== "") {
        this.deshabilitado_estado = true;
      }
      this.pais = item.pais ? item.pais : "";
      if (this.pais !== "") {
        this.deshabilitado_pais = true;
      }
      this.ciudad = item.ciudad ? item.ciudad : "";
      if (this.ciudad !== "") {
        this.deshabilitado_ciudad = true;
      }
      this.postal = item.postal ? item.postal : "";
      if (this.postal !== "") {
        this.deshabilitado_postal = true;
      }
      this.licencia = item.licencia ? item.licencia.numero : "";
      if (this.licencia !== "") {
        this.deshabilitado_licencia = true;
      }
      this.licencia_permanente = item.licencia ? item.licencia.permanente : "";
      this.exp_licencia = item.licencia ? item.licencia.vencimiento : null;
      if (this.exp_licencia !== null) {
        this.deshabilitado_lic_exp = true;
      }
      this.estado_licencia = item.licencia ? item.licencia.estado ? item.licencia.estado : "" : "";
      if (this.estado_licencia !== "") {
        this.deshabilitado_lic_estado = true;
      }
      this.paisLicencia = item.licencia ? item.licencia.pais ? item.licencia.pais : "" : "";
      if (this.paisLicencia !== "") {
        this.deshabilitado_lic_pais = true;
      }
      this.showSubmenu = false;
    },
    Clear_Data() {
      this.deshabilitado = false;
      this.nombre = "";
      this.apellidos = "";
      this.tipo_cliente = 0;
      this.edad = "";
      this.email = "";
      this.phone = "";
      this.emergency_phone = "";
      this.direccion = "";
      this.estado = "";
      this.pais = "";
      this.ciudad = "";
      this.postal = "";
      this.licencia = "";
      this.licencia_permanente = "";
      this.exp_licencia = null;
      this.estado_licencia = "";
      this.paisLicencia = "";
      this.tarjeta = "";
      this.exp_tarjeta = null;
      this.tarjeta_autorizacion = "";
      this.deshabilitado_tipo = false;
      this.deshabilitado_phone = false;
      this.deshabilitado_estado = false;
      this.deshabilitado_pais = false;
      this.deshabilitado_ciudad = false;
      this.deshabilitado_postal = false;
      this.deshabilitado_licencia = false;
      this.deshabilitado_lic_exp = false;
      this.deshabilitado_lic_estado = false;
      this.deshabilitado_lic_pais = false;
      this.deshabilitado_tarjeta = false;
      this.deshabilitado_tarj_exp = false;
      this.deshabilitado_tarj_aut = false;
      this.deshabilitado_direccion = false;
      this.showSubmenu = false;
    },
    DecodeId(id) {
      if (this.isBase64(id)) {
        return decodeURIComponent(escape(window.atob(id))).split(":")[1];
      } else {
        return id;
      }

    },
    Next_Dos() {
      var validacion = true;
      if (this.sucursal === 0) {
        this.error_sucursal = true;
        validacion = false;
      }
      if (this.agente === 0) {
        this.error_agente = true;
        validacion = false;
      }
      if (this.ciudad === 0) {
        this.error_ciudad = true;
        validacion = false;
      }
      if (this.estado === 0) {
        this.error_estado = true;
        validacion = false;
      }
      if (this.canal === "") {
        this.error_canal = true;
        validacion = false;
      }
      if (this.nombre === "") {
        this.error_nombre = true;
        validacion = false;
      }
      if (this.apellidos === "") {
        this.error_apellidos = true;
        validacion = false;
      }
      if (this.edad === 0 || this.edad < 21) {
        this.error_edad = true;
        validacion = false;
      }
      if (this.tipo_cliente === null || this.tipo_cliente === 0 || this.tipo_cliente === "") {
        this.error_tipo = true;
        validacion = false;
      }
      if (this.phone === "" || !this.phone_result.isValid) {
        this.error_phone = true;
        validacion = false;
      }
      if (this.emergency_phone === "" || !this.phoneEmergency_result.isValid) {
        this.error_emergency_phone = true;
        validacion = false;
      }
      if (this.email === "") {
        this.error_email = true;
        validacion = false;
      }
      if (this.direccion === "") {
        this.error_direccion = true;
        validacion = false;
      }
      if (this.postal === "") {
        this.error_postal = true;
        validacion = false;
      }
      if (this.salida === null) {
        this.error_salida = true;
        validacion = false;
      }
      if (this.regreso === null) {
        this.error_regreso = true;
        validacion = false;
      }
      if (this.Difechas(this.salida, this.regreso) <= 0) {
        this.error_regreso = true;
        validacion = false;
      }
      if (validacion) {
        this.activeStep++;
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Debe llenar todos los campos"
        });
        return false;
      }

    },
    Next_Tres() {
      var validacion = true;
      if (this.categoria === null) {
        this.error_categoria = true;
        validacion = false;
      }
      if (this.vehiculo === 0) {
        this.error_vehiculo = true;
        validacion = false;
      }
      if (this.Difechas(new Date(), this.exp_licencia) <= 0 && this.licencia_permanente === false) {
        this.error_lic_exp = true;
        this.deshabilitado_lic_exp = false;
        validacion = false;
      }
      if (this.exp_licencia === null && this.licencia_permanente === false) {
        this.error_lic_exp = true;
        this.deshabilitado_lic_exp = false;
        validacion = false;
      }
      if (this.estado_licencia === "") {
        this.error_lic_estado = true;
        validacion = false;
      }
      if (this.paisLicencia === "") {
        this.error_lic_pais = true;
        validacion = false;
      }
      if (this.licencia === "") {
        this.error_licencia = true;
        validacion = false;
      }
      if (this.conductor_extra) {
        if (this.extra_nombre === "") {
          this.error_extra_nombre = true;
          validacion = false;
        }
        if (this.extra_apellidos === "") {
          this.error_extra_apellidos = true;
          validacion = false;
        }
        if (this.extra_edad === 0) {
          this.error_extra_edad = true;
          validacion = false;
        }
        if (this.extra_licencia === "") {
          this.error_extra_licencia = true;
          validacion = false;
        }
        if (this.extra_estado === "") {
          this.error_extra_estado = true;
          validacion = false;
        }
        if (this.extra_pais === "") {
          this.error_extra_pais = true;
          validacion = false;
        }
        if (this.extra_exp_licencia === null && this.licencia_permanente_extra === false) {
          this.error_exp_extra = true;
          validacion = false;
        }
      }
      if (validacion) {
        this.Calcular();
        this.activeStep++;
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Debe llenar todos los campos"
        });
        return false;
      }
    },
    Next_Cuatro() {
      if (21 <= this.edad && this.edad <= 24) {
        if (this.extra_conductor_menor) {
          this.activeStep++;
          return false;
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Debe contratar el servicio de conductor menor"
          });
          return false;
        }
      } else {
        this.activeStep++;
      }
    },
    prevStep() {
      this.activeStep--;
    },
    async Guardar() {
      this.$store.state.Cargando = true;
      var validacion = true;
      if (this.eliminarComa(this.tarifa_hora_extra) === 0) {
        validacion = false;
        this.error_tarifa_hora_extra = true;
      }
      if (this.eliminarComa(this.tarifa_diaria) === 0) {
        validacion = false;
        this.error_tarifa_diaria = true;
      }

      if (this.tipo_pago === "tarjeta" && this.credito === false) {
        if (this.tarjeta === "" || this.tarjeta.length > 16 || this.tarjeta.length < 15) {
          validacion = false;
          this.error_tarjeta = true;
        }
        if (this.tarjeta_autorizacion === "") {
          validacion = false;
          this.error_tarjeta_autorizacion = true;
        }
        if (this.exp_tarjeta === null) {
          validacion = false;
          this.error_exp_tarjeta = true;
        }
        if (this.Difechas(this.exp_tarjeta, Date.now()) >= 0) {
          validacion = false;
          this.error_exp_tarjeta = true;
        }
      }
      if (validacion) {
        createLicencia({
          estado: this.estado_licencia,
          pais: this.paisLicencia,
          numero: this.licencia,
          vencimiento: moment(this.exp_licencia).format("YYYY-MM-DD"),
          permanente: this.licencia_permanente
        }).then(response => {
          if (response.data.data.createLicencia.success) {
            this.nuevaLicencia = response.data.data.createLicencia.licencia;
            createCliente({
              apellidos: this.apellidos,
              edad: this.edad,
              email: this.email,
              codigo: this.cod_telefono,
              nombre: this.nombre,
              telefono: this.phone,
              tipo: this.tipo_cliente.id,
              ciudad: this.ciudad,
              pais: this.pais,
              estado: this.estado,
              licencia: this.nuevaLicencia.id,
              postal: this.postal,
              telefonoEmergencia: this.emergency_phone,
              direccion: this.direccion
            }).then(response => {
              if (response.data.data.createCliente.success) {
                this.cliente = response.data.data.createCliente.cliente;
                if (this.conductor_extra) {
                  createLicencia({
                    estado: this.extra_estado,
                    pais: this.extra_pais,
                    numero: this.extra_licencia,
                    vencimiento: moment(this.extra_exp_licencia).format("YYYY-MM-DD"),
                    permanente: this.licencia_permanente_extra
                  }).then(respLicenciaExtra => {
                    createDriver(
                      {
                        apellidos: this.extra_apellidos,
                        cliente: this.cliente.id,
                        edad: this.extra_edad,
                        licencia: respLicenciaExtra.data.data.createLicencia.licencia.id,
                        nombre: this.extra_nombre
                      }).then(respDriver => {
                      this.conductor = respDriver.data.data.createExtraDriver.conductor;
                      if (this.tarjeta === "" || this.tarjeta_autorizacion === "" || this.exp_tarjeta === null) {
                        updateContract({
                          id: decodeBase64(this.contrato.id),
                          checkin: this.eliminarComa(this.check),
                          cliente: parseInt(this.cliente.id),
                          comentarios: this.comentarios,
                          montoCombustible: this.eliminarComa(this.total),
                          conductor: this.conductor ? this.conductor.id : 0,
                          credito: this.credito,
                          depositoEfectivo: this.eliminarComa(this.deposito),
                          dias: this.dias,
                          fechaRegreso: this.regreso,
                          fechaSalida: this.salida,
                          precioHoraExtra: this.eliminarComa(this.tarifa_hora_extra),
                          descuento: this.eliminarComa(this.descuento_cortesia),
                          sucursal: this.sucursal,
                          tanque: this.tanque_lleno,
                          tarifaDiaria: this.eliminarComa(this.tarifa_diaria),
                          tarifaExtra: this.eliminarComa(this.tarifa_extra),
                          deuda: this.credito ? this.eliminarComa(this.pago_pendiente) : 0,
                          total: this.eliminarComa(this.total_pagado),
                          vehiculo: this.DecodeId(this.vehiculo),
                          agente: this.$store.state.userid,
                          canal: this.canal,
                          tipo: this.tipo_pago,
                          tarjeta: 0,
                          servicios: this.servicios_reservados,
                          reserva: this.reserva === null ? 0 : this.DecodeId(this.reserva.id),
                          tipoCobertura: this.cobertura,
                          seguroRc: this.seguro_rc,
                          cortesia: this.cortesia
                        }).then(response => {
                          if (response.data.data.updateContrato.success) {
                            if (!this.extra_service_add) {
                              createExtraServiceToContract({
                                daily: this.diario_otro,
                                description: this.description_extra_service,
                                price: this.precio_otro,
                                contract: response.data.data.updateContrato.contrato
                              }).then(resp => {
                                if (!resp.data.data.createServiceToContract.success) {
                                  console.log(resp.data.data.createServiceToContract.error);
                                }
                              });
                            }
                            this.$store.state.vehiculo = null;
                            this.$store.state.reserva = null;
                            this.$router.push("/contratos/abiertos");
                          } else {
                            const Toast = Swal.mixin({
                              toast: true,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: 3000,
                              timerProgressBar: true,
                              didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                              }
                            });
                            Toast.fire({
                              icon: "error",
                              title: "Error al crear el contrato"
                            });
                            this.$store.state.Cargando = false;
                          }
                        }).catch(error => {
                          const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener("mouseenter", Swal.stopTimer);
                              toast.addEventListener("mouseleave", Swal.resumeTimer);
                            }
                          });
                          Toast.fire({
                            icon: "error",
                            title: error
                          });
                          this.$store.state.Cargando = false;
                        });
                      } else {
                        createTarjeta({
                          numero: this.tarjeta,
                          autorizacion: this.tarjeta_autorizacion,
                          vencimiento: moment(this.exp_tarjeta).format("YYYY-MM-DD")
                        }).then(response => {
                          if (response.data.data.createTarjeta.success) {
                            this.nuevaTarjeta = response.data.data.createTarjeta.tarjeta;
                            updateContract({
                              id: decodeBase64(this.contrato.id),
                              checkin: this.eliminarComa(this.check),
                              cliente: parseInt(this.cliente.id),
                              descuento: this.eliminarComa(this.descuento_cortesia),
                              comentarios: this.comentarios,
                              montoCombustible: this.eliminarComa(this.total),
                              conductor: this.conductor ? this.conductor.id : 0,
                              credito: this.credito,
                              depositoEfectivo: this.eliminarComa(this.deposito),
                              dias: this.dias,
                              fechaRegreso: this.regreso,
                              fechaSalida: this.salida,
                              precioHoraExtra: this.eliminarComa(this.tarifa_hora_extra),
                              sucursal: this.sucursal,
                              tanque: this.tanque_lleno,
                              tarifaDiaria: this.eliminarComa(this.tarifa_diaria),
                              tarifaExtra: this.eliminarComa(this.tarifa_extra),
                              deuda: this.credito ? this.eliminarComa(this.pago_pendiente) : 0,
                              total: this.eliminarComa(this.total_pagado),
                              vehiculo: this.DecodeId(this.vehiculo),
                              agente: this.$store.state.userid,
                              canal: this.canal,
                              tipo: this.tipo_pago,
                              tarjeta: 0,
                              servicios: this.servicios_reservados,
                              reserva: this.reserva === null ? 0 : this.DecodeId(this.reserva.id),
                              tipoCobertura: this.cobertura,
                              seguroRc: this.seguro_rc,
                              cortesia: this.cortesia
                            }).then(response => {
                              if (response.data.data.updateContrato.success) {
                                if (!this.extra_service_add) {
                                  createExtraServiceToContract({
                                    daily: this.diario_otro,
                                    description: this.description_extra_service,
                                    price: this.precio_otro,
                                    contract: response.data.data.updateContrato.contrato
                                  }).then(resp => {
                                    if (!resp.data.data.createServiceToContract.success) {
                                      console.log(resp.data.data.createServiceToContract.error);
                                    }
                                  });
                                }
                                this.$store.state.vehiculo = null;
                                this.$store.state.reserva = null;
                                this.$router.push("/contratos/abiertos");
                              } else {
                                const Toast = Swal.mixin({
                                  toast: true,
                                  position: "top-end",
                                  showConfirmButton: false,
                                  timer: 3000,
                                  timerProgressBar: true,
                                  didOpen: (toast) => {
                                    toast.addEventListener("mouseenter", Swal.stopTimer);
                                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                                  }
                                });
                                Toast.fire({
                                  icon: "error",
                                  title: "Error al crear el contrato"
                                });
                                this.$store.state.Cargando = false;
                              }
                            }).catch(error => {
                              const Toast = Swal.mixin({
                                toast: true,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                  toast.addEventListener("mouseenter", Swal.stopTimer);
                                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                                }
                              });
                              Toast.fire({
                                icon: "error",
                                title: error
                              });
                              this.$store.state.Cargando = false;
                            });
                          }
                          this.$store.state.Cargando = false;
                        });
                      }
                    });
                  });
                } else {
                  if (this.tarjeta === "" || this.tarjeta_autorizacion === "" || this.exp_tarjeta === null) {
                    updateContract({
                      id: decodeBase64(this.contrato.id),
                      checkin: this.eliminarComa(this.check),
                      cliente: parseInt(this.cliente.id),
                      montoCombustible: this.eliminarComa(this.total),
                      comentarios: this.comentarios,
                      descuento: this.eliminarComa(this.descuento_cortesia),
                      conductor: this.conductor ? this.conductor.id : 0,
                      credito: this.credito,
                      depositoEfectivo: this.eliminarComa(this.deposito),
                      dias: this.dias,
                      fechaRegreso: this.regreso,
                      fechaSalida: this.salida,
                      precioHoraExtra: this.eliminarComa(this.tarifa_hora_extra),
                      sucursal: this.sucursal,
                      tanque: this.tanque_lleno,
                      tarifaDiaria: this.eliminarComa(this.tarifa_diaria),
                      tarifaExtra: this.eliminarComa(this.tarifa_extra),
                      deuda: this.credito ? this.eliminarComa(this.pago_pendiente) : 0,
                      total: this.eliminarComa(this.total_pagado),
                      vehiculo: this.DecodeId(this.vehiculo),
                      agente: this.$store.state.userid,
                      canal: this.canal,
                      tipo: this.tipo_pago,
                      tarjeta: 0,
                      servicios: this.servicios_reservados,
                      reserva: this.reserva === null ? 0 : this.DecodeId(this.reserva.id),
                      tipoCobertura: this.cobertura,
                      seguroRc: this.seguro_rc,
                      cortesia: this.cortesia
                    }).then(response => {
                      if (response.data.data.updateContrato.success) {
                        if (!this.extra_service_add) {
                          createExtraServiceToContract({
                            daily: this.diario_otro,
                            description: this.description_extra_service,
                            price: this.precio_otro,
                            contract: response.data.data.updateContrato.contrato
                          }).then(resp => {
                            if (!resp.data.data.createServiceToContract.success) {
                              console.log(resp.data.data.createServiceToContract.error);
                            }
                          });
                        }
                        this.$store.state.vehiculo = null;
                        this.$store.state.reserva = null;
                        this.$router.push("/contratos/abiertos");
                      } else {
                        const Toast = Swal.mixin({
                          toast: true,
                          position: "top-end",
                          showConfirmButton: false,
                          timer: 3000,
                          timerProgressBar: true,
                          didOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                          }
                        });
                        Toast.fire({
                          icon: "error",
                          title: "Error al crear el contrato"
                        });
                        this.$store.state.Cargando = false;
                      }
                    }).catch(error => {
                      const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.addEventListener("mouseenter", Swal.stopTimer);
                          toast.addEventListener("mouseleave", Swal.resumeTimer);
                        }
                      });
                      Toast.fire({
                        icon: "error",
                        title: error
                      });
                      this.$store.state.Cargando = false;
                    });
                  } else {
                    createTarjeta({
                      numero: this.tarjeta,
                      autorizacion: this.tarjeta_autorizacion,
                      vencimiento: moment(this.exp_tarjeta).format("YYYY-MM-DD")
                    }).then(response => {
                      if (response.data.data.createTarjeta.success) {
                        this.nuevaTarjeta = response.data.data.createTarjeta.tarjeta;
                        updateContract({
                          id: decodeBase64(this.contrato.id),
                          checkin: this.eliminarComa(this.check),
                          cliente: parseInt(this.cliente.id),
                          montoCombustible: this.eliminarComa(this.total),
                          descuento: this.eliminarComa(this.descuento_cortesia),
                          comentarios: this.comentarios,
                          conductor: this.conductor ? this.conductor.id : 0,
                          credito: this.credito,
                          depositoEfectivo: this.eliminarComa(this.deposito),
                          dias: this.dias,
                          fechaRegreso: this.regreso,
                          fechaSalida: this.salida,
                          precioHoraExtra: this.eliminarComa(this.tarifa_hora_extra),
                          sucursal: this.sucursal,
                          tanque: this.tanque_lleno,
                          tarifaDiaria: this.eliminarComa(this.tarifa_diaria),
                          tarifaExtra: this.eliminarComa(this.tarifa_extra),
                          deuda: this.credito ? this.eliminarComa(this.pago_pendiente) : 0,
                          total: this.eliminarComa(this.total_pagado),
                          vehiculo: this.DecodeId(this.vehiculo),
                          agente: this.$store.state.userid,
                          canal: this.canal,
                          tipo: this.tipo_pago,
                          tarjeta: 0,
                          servicios: this.servicios_reservados,
                          reserva: this.reserva === null ? 0 : this.DecodeId(this.reserva.id),
                          tipoCobertura: this.cobertura,
                          seguroRc: this.seguro_rc,
                          cortesia: this.cortesia
                        }).then(response => {
                          if (response.data.data.updateContrato.success) {
                            if (!this.extra_service_add) {
                              createExtraServiceToContract({
                                daily: this.diario_otro,
                                description: this.description_extra_service,
                                price: this.precio_otro,
                                contract: response.data.data.updateContrato.contrato
                              }).then(resp => {
                                if (!resp.data.data.createServiceToContract.success) {
                                  console.log(resp.data.data.createServiceToContract.error);
                                }
                              });
                            }
                            this.$store.state.vehiculo = null;
                            this.$store.state.reserva = null;
                            this.$router.push("/contratos/abiertos");
                          } else {
                            const Toast = Swal.mixin({
                              toast: true,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: 3000,
                              timerProgressBar: true,
                              didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                              }
                            });
                            Toast.fire({
                              icon: "error",
                              title: "Error al crear el contrato"
                            });
                            this.$store.state.Cargando = false;
                          }
                        }).catch(error => {
                          const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener("mouseenter", Swal.stopTimer);
                              toast.addEventListener("mouseleave", Swal.resumeTimer);
                            }
                          });
                          Toast.fire({
                            icon: "error",
                            title: error
                          });
                          this.$store.state.Cargando = false;
                        });
                      }
                      this.$store.state.Cargando = false;
                    });
                  }
                }
              }
            });
          }
          this.$store.state.Cargando = false;
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Debe llenar todos los campos"
        });
        this.$store.state.Cargando = false;
        return false;
      }
    },
    Difechas(fecha1, fecha2) {
      const diffInHours = moment(fecha2).diff(fecha1, "hours");
      return Math.ceil(diffInHours / 24);
    },
    Regresar() {
      if (this.$store.state.update_contrato === "vencidos") {
        this.$router.push("/contratos/vencidos");
        return false;
      }
      if (this.$store.state.update_contrato === "abiertos") {
        this.$router.push("/contratos/abiertos");
        return false;
      }
    },
    openSeguridad() {
      this.seguridad = true;
      this.deshabilitado = false;
      document.body.classList.add("modal-open");
    },
    Comprobar() {
      this.$store.state.Cargando = true;
      if (this.password === "") {
        this.error = true;
        this.$store.state.Cargando = false;
      } else {
        this.$apollo.mutate({
          mutation: SEGURIDAD,
          variables: {
            email: this.$store.state.email,
            password: this.password
          }
        }).then(response => {
          if (response.data.login.success) {
            this.$store.state.Cargando = false;
            this.seguridad = false;
            this.password = "";
          } else {
            this.$store.state.Cargando = false;
            this.error_seguridad = true;
          }
        });
      }
    },
    closeSeguridad() {
      this.seguridad = false;
      this.password = "";
      if (this.$store.state.anterior === "Disponibles") {
        this.$router.push("/vehiculos/disponibles");
        return false;
      }
      if (this.$store.state.anterior === "Reservados") {
        this.$router.push("/vehiculos/reservados");
        return false;
      }
      if (this.$store.state.anterior === "Clientes") {
        this.$router.push("/clientes/listado");
        return false;
      }
      if (this.$store.state.anterior === "Rentas_Admin") {
        this.$router.push("/transacciones/administracion");
        return false;
      }
      if (this.$store.state.anterior === "entrega_autos") {
        this.$router.push("/entrega_autos");
        return false;
      }
      if (this.$store.state.anterior === "Rentas_Portal") {
        this.$router.push("/transacciones/portal");
        return false;
      } else {
        this.$router.push("/transacciones/portal");
        return false;
      }
    },
    paginaAnterior() {
      if (this.paginaActual > 1) {
        this.paginaActual--;
      }
    },
    irPaginaActual(index) {
      this.paginaActual = index;
    },
    paginaSiguiente() {
      if (this.paginaActual < this.totalPaginas) {
        this.paginaActual++;
      }
    },
    Calcular_Extras() {
      this.tarifa_extra = 0;
      this.services_calc.forEach(item => {
        if (item.diario) {
          this.tarifa_extra = parseFloat(this.eliminarComa(this.tarifa_extra) + (parseFloat(item.precio) * parseInt(this.dias))).toFixed(2);
        } else {
          this.tarifa_extra = parseFloat(parseFloat(item.precio) + this.eliminarComa(this.tarifa_extra)).toFixed(2);
        }
      });
    },
    Adicionar(id, precio, diario, nombre) {
      this.services_calc.push({
        "precio": parseFloat(precio),
        "id": id,
        "diario": diario,
        "nombre": nombre
      });
      this.Calcular_Extras();
      this.Calcular();

      const texto = nombre.toLowerCase();
      if (texto.includes("conductor adicional")) {
        this.conductor_extra = true;
      }
      if (texto.includes("seguro rc")) {
        this.seguro_rc = true;
      }
      if (texto.includes("menor")) {
        this.extra_conductor_menor = true;
      }
      if (id === "otro") {
        this.extra_service_add = false;
      } else if (!this.servicios_reservados.includes(id)) {
        this.servicios_reservados.push(id);
      }
      if (!this.servicios_contratados.includes(nombre)) {
        this.servicios_contratados.push(nombre);
      }
    },
    Quitar(id, precio, diario, nombre) {
      let services_temp = [];
      this.services_calc.forEach(item => {
        if (item.id !== id) {
          services_temp.push(item);
        }
      });
      this.services_calc = services_temp;
      this.Calcular_Extras();
      this.Calcular();
      const texto = nombre.toLowerCase();
      if (texto.includes("conductor adicional")) {
        this.conductor_extra = false;
      }
      if (texto.includes("seguro rc")) {
        this.seguro_rc = false;
      }
      if (texto.includes("menor")) {
        this.extra_conductor_menor = false;
      }
      const index = this.servicios_reservados.indexOf(id);
      const index1 = this.servicios_contratados.indexOf(nombre);
      if (id === "otro") {
        this.extra_service_add = true;
      } else if (index > -1) {
        this.servicios_reservados.splice(index, 1);
      }
      if (index1 > -1) {
        this.servicios_contratados.splice(index1, 1);
      }
    },
    getURL(url) {
      if (url !== "") {
        return this.$store.state.store_url + url;
      } else {
        return noImagen;
      }
    },
    eliminarComa(str) {
      const numero_convertido = str.toString();
      if (numero_convertido.includes(",")) {
        return parseFloat(numero_convertido.replace(/,\s*/, ""));
      } else {
        return parseFloat(numero_convertido);
      }
    },
    Calc_Cortesia() {
      if (this.cortesia !== 0) {
        this.$apollo.query({
          query: CORTESIA,
          variables: {
            id: this.cortesia
          }
        }).then(resp => {
          const item = resp.data.cortesiaById;
          this.descuento_cortesia = parseFloat(parseInt(this.dias) * this.eliminarComa(this.tarifa_diaria) * parseFloat(item.descuento) / 100).toFixed(2);
          this.porciento_cortesia = item.descuento;
          this.Calcular();
        });
      } else {
        this.descuento_cortesia = 0;
        this.porciento_cortesia = 0;
        this.Calcular();
      }
    },
    Calcular() {
      if (this.dias > 0 && this.eliminarComa(this.tarifa_diaria) > 0) {
        this.total_pagado = parseFloat(parseInt(this.dias) * this.eliminarComa(this.tarifa_diaria) + this.eliminarComa(this.tarifa_extra)).toFixed(2);
        this.tarifa_hora_extra = parseFloat(this.eliminarComa(this.tarifa_diaria) / 5).toFixed(2);
        if (this.tanque_lleno) {
          this.total_pagado = parseFloat(this.eliminarComa(this.total_pagado) + this.eliminarComa(this.total)).toFixed(2);
        }
        this.pago_pendiente = parseFloat(this.eliminarComa(this.total_pagado) - this.eliminarComa(this.monto_acreditado)).toFixed(2);
        if (this.eliminarComa(this.pago_pendiente) < 0) {
          this.pago_pendiente = 0;
        }
        if (this.cortesia !== 0 && this.eliminarComa(this.pago_pendiente) > 0) {
          this.total_contrato = parseFloat(this.eliminarComa(this.pago_pendiente) - this.eliminarComa(this.descuento_cortesia) > 0 ? this.eliminarComa(this.pago_pendiente) - this.eliminarComa(this.descuento_cortesia) : 0).toFixed(2);
          if (this.eliminarComa(this.total_contrato) < 0) {
            this.cortesia = 0;
            this.total_contrato = parseFloat(this.eliminarComa(this.pago_pendiente)).toFixed(2);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "No tiene saldo suficiente para aplicar la cortesía"
            });
          }
        } else {
          this.total_contrato = parseFloat(this.eliminarComa(this.pago_pendiente) - this.eliminarComa(this.descuento_cortesia) > 0 ? this.eliminarComa(this.pago_pendiente) - this.eliminarComa(this.descuento_cortesia) : 0).toFixed(2);
        }
      }
    },
    Buscar_Vehiculo() {
      if (this.salida !== null && this.regreso !== null && this.sucursal !== 0 &&
        this.categoria !== null && this.sucursal !== null && this.categoria !== 0) {
        this.$apollo.query({
          query: DISPONIBLES,
          variables: {
            categoria: this.categoria.id,
            fechaSalida: this.salida,
            fechaRegreso: this.regreso
          },
          fetchPolicy: "network-only"
        }).then(resp => {
          this.vehiculosPerName = resp.data.vehiculosDisponibles.edges.map(value => {
            return value.node;
          });
        });
      }
    },
    Temporada_alta() {
      if (this.salida !== null && this.regreso !== null) {
        this.$apollo.query({
          query: TEMPORADA,
          variables: {
            fechaInicio: new Date(this.salida).toISOString().slice(0, 10),
            fechaFin: new Date(this.regreso).toISOString().slice(0, 10)
          }
        }).then(response => {
          this.Alta = response.data.temporadaAlta;
        });
      }
    }
  },
  watch: {
    password() {
      this.misearch = "";
    },
    cortesia() {
      this.Calc_Cortesia();
    },
    cobertura(val) {
      if (!this.inicio) {
        if (val === "total") {
          this.tarifa_diaria = parseFloat(this.tarifa_total).toFixed(2);
        } else {
          this.tarifa_diaria = parseFloat(this.tarifa_tradicional).toFixed(2);
        }
        this.Calcular();
      }

    },
    categoria(value) {
      if (this.Alta) {
        this.tarifa_total = parseFloat(this.categoria.totalAlta).toFixed(2);
        this.tarifa_tradicional = parseFloat(this.categoria.tradicionalAlta).toFixed(2);
        if (this.cobertura === "total") {
          this.tarifa_diaria = this.tarifa_diaria === 0 ? parseFloat(this.tarifa_total).toFixed(2) : this.tarifa_diaria;
        } else {
          this.tarifa_diaria = this.tarifa_diaria === 0 ? parseFloat(this.tarifa_tradicional).toFixed(2) : this.tarifa_diaria;
        }
      } else {
        this.tarifa_total = parseFloat(this.categoria.totalBaja).toFixed(2);
        this.tarifa_tradicional = parseFloat(this.categoria.tradicionalBaja).toFixed(2);
        if (this.cobertura === "total") {
          this.tarifa_diaria = this.tarifa_diaria === 0 ? parseFloat(this.tarifa_total).toFixed(2) : this.tarifa_diaria;
        } else {
          this.tarifa_diaria = this.tarifa_diaria === 0 ? parseFloat(this.tarifa_tradicional).toFixed(2) : this.tarifa_diaria;
        }
      }
      this.Buscar_Vehiculo();
      if (value !== null) {
        this.error_categoria = false;
      }
    },
    vehiculo(val) {
      this.$apollo.query({
        query: VEHICULO_PER_ID,
        variables: {
          id: this.DecodeId(this.vehiculo)
        }
      }).then(response => {
        const auto = response.data.vehiculoPerId;
        this.vehiculo_contract = auto;
        this.tanque = parseInt(auto.capacidadTanke);
        this.marca = auto.version.modelo.marca.name;
        this.modelo = auto.version.modelo.name;
        this.color = auto.color;
        this.placa = auto.placa;
      });
      if (val !== 0) {
        this.error_vehiculo = false;
      }
    },
    salida(val) {
      if (this.Difechas(val, this.regreso) <= 0) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "La fecha de regreso no puede ser anterior a la fecha de renta"
        });
        this.regreso = null;
        return false;
      }
      if (this.regreso !== null && val !== null && val !== "" && this.regreso !== "") {
        this.$apollo.query({
          query: ALLCATEGORIASADMIN,
          variables: {
            fechaRenta: this.salida,
            fechaRegreso: this.regreso
          }
        })
          .then(response => {
            this.categorias_id = [];
            const categorias_disponibles = response.data.categoriasDisponiblesAdmin;
            categorias_disponibles.forEach(item => {
              this.categorias_id.push(item.id);
            });
          });
        this.dias = this.Difechas(val, this.regreso);
        this.total_pagado = parseFloat((parseInt(this.dias) * parseFloat(this.tarifa_diaria)) + parseFloat(this.tarifa_extra) + parseFloat(this.total) + parseFloat(this.seguro_total)).toFixed(2);
      }
      if (val !== null) {
        this.error_salida = false;
      }
      this.Temporada_alta();
      this.Buscar_Vehiculo();
    },
    regreso(val) {
      if (this.Difechas(this.salida, val) <= 0) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "La fecha de regreso no puede ser anterior a la fecha de renta"
        });
        this.regreso = null;
        return false;
      }
      if (this.salida !== null && val !== null && val !== "" && this.salida !== "") {
        this.$apollo.query({
          query: ALLCATEGORIASADMIN,
          variables: {
            fechaRenta: this.salida,
            fechaRegreso: this.regreso
          }
        })
          .then(response => {
            this.categorias_id = [];
            const categorias_disponibles = response.data.categoriasDisponiblesAdmin;
            categorias_disponibles.forEach(item => {
              this.categorias_id.push(item.id);
            });
          });
        this.dias = this.Difechas(this.salida, val);
        this.total_pagado = parseFloat((parseInt(this.dias) * parseFloat(this.tarifa_diaria)) + parseFloat(this.tarifa_extra) + parseFloat(this.total) + parseFloat(this.seguro_total)).toFixed(2);
      }
      if (val !== null) {
        this.error_regreso = false;
      }
      this.Temporada_alta();
      this.Buscar_Vehiculo();
    },
    tanque_lleno(value) {
      if (value) {
        this.total = parseFloat(this.eliminarComa(this.precio_gasolina) * parseInt(this.tanque)).toFixed(2);
        this.Calcular();
      } else {
        this.total = 0;
        this.Calcular();
      }
    },
    serviciosPerName() {
      this.totalPaginas = Math.ceil(this.serviciosPerName.length / this.elementosPorPagina);
      this.serviciosPerName.forEach(item => {
        serviceByContract({
          idServicio: item ? item.id : 0,
          idContrato: this.contrato ? this.contrato.id : 0
        }).then(response => {
          this.services[item.id] = response.data.data.servicioPorContrato;
          if (response.data.data.servicioPorContrato) {
            this.Adicionar(item.id, item.precio, item.diario, item.name);
          }
        });
        if (21 <= this.edad && this.edad <= 24) {
          if (item.name.toLowerCase().includes("menor")) {
            this.services[item.id] = true;
            if (!this.servicios_reservados.includes(item.id)) {
              this.Adicionar(item.id, item.precio, item.diario, item.name);
              this.extra_conductor_menor = true;
            }
          }
        }
      });
    },
    sucursal(value) {
      if (value !== 0) {
        this.error_sucursal = false;
      }

    },
    canal(value) {
      if (value !== "") {
        this.error_canal = false;
      }
    },
    agente(value) {
      if (value !== 0) {
        this.error_agente = false;
      }
    },
    nombre(value) {
      if (value !== "") {
        this.error_nombre = false;
      }
    },
    apellidos(value) {
      if (value !== "") {
        this.error_apellidos = false;
      }
    },
    edad(value) {
      if (value !== 0) {
        this.error_edad = false;
      }
    },
    email(value) {
      if (value !== "") {
        this.error_email = false;
      }
    },
    phone(value) {
      if (value !== "") {
        this.error_phone = false;
      }
    },
    tipo_cliente(value) {
      if (value !== null) {
        this.error_tipo = false;
      }
    },
    emergency_phone(value) {
      if (value !== "") {
        this.error_emergency_phone = false;
      }
    },
    direccion(value) {
      if (value !== "") {
        this.error_direccion = false;
      }
    },
    estado(value) {
      if (value !== 0) {
        this.error_estado = false;
      }
    },
    ciudad(value) {
      if (value !== 0) {
        this.error_ciudad = false;
      }
    },
    postal(value) {
      if (value !== "") {
        this.error_postal = false;
      }
    },

    exp_licencia(value) {
      if (value !== null || this.Difechas(new Date(), value) > 0) {
        this.error_lic_exp = false;
      }
    },
    estado_licencia(value) {
      if (value !== 0) {
        this.error_lic_estado = false;
      }
    },
    licencia(value) {
      if (value !== "") {
        this.error_licencia = false;
      }
    },
    extra_nombre(value) {
      if (value !== "") {
        this.error_extra_nombre = false;
      }
    },
    extra_apellidos(value) {
      if (value !== "") {
        this.error_extra_apellidos = false;
      }
    },
    extra_edad(value) {
      if (value !== "") {
        this.error_extra_edad = false;
      }
    },
    extra_licencia(value) {
      if (value !== "") {
        this.error_extra_licencia = false;
      }
    },
    extra_estado(value) {
      if (value !== "") {
        this.error_extra_estado = false;
      }
    },
    exp_extra(value) {
      if (value !== "") {
        this.error_exp_extra = false;
      }
    },
    tarifa_hora_extra(value) {
      this.inicio = false;
      if (value !== 0) {
        this.error_tarifa_hora_extra = false;
      }
    },
    tarifa_diaria(value) {
      if (value !== 0) {
        this.error_tarifa_diaria = false;
      }
    },
    tarjeta(value) {
      if (value !== "") {
        this.error_tarjeta = false;
      }
    },
    tarjeta_autorizacion(value) {
      if (value !== "") {
        this.error_tarjeta_autorizacion = false;
      }
    },
    exp_tarjeta(value) {
      if (value !== null) {
        this.error_exp_tarjeta = false;
      }
    },
    deposito(value) {
      if (value !== 0) {
        this.error_deposito_efectivo = false;
      }
    },
    credito(value) {
      value ? this.pagar_diferencia = false : this.pagar_diferencia = true;
    },
    dias() {
      this.Calcular_Extras();
      this.Calc_Cortesia();
    }
  },
  created() {
    this.get_Offices();
    this.get_Discounts();
    this.get_all_clients();
    this.get_all_users();
    this.get_all_client_types();
    this.get_all_categories();
    this.get_all_branch();
    this.get_car_by_categorie();
    this.get_all_secures();

    this.agente = this.$store.state.userid;
    this.sucursal = this.$store.state.contrato.sucursal ? this.$store.state.contrato.sucursal.id : 0;
    this.categoria_name = this.$store.state.contrato ? this.$store.state.contrato.vehiculo.categoria.name : null;
    this.categoria_id = this.$store.state.contrato ? this.$store.state.contrato.vehiculo.categoria : null;
    this.categoria = this.$store.state.contrato ? this.$store.state.contrato.vehiculo.categoria : null;
    this.vehiculo = this.$store.state.contrato ? this.$store.state.contrato.vehiculo.id : null;
    this.tanque = this.$store.state.contrato ? this.$store.state.contrato.vehiculo.capacidadTanke : 0;
    this.vehiculo_reservado = this.$store.state.contrato ? this.$store.state.contrato.vehiculo : null;
    this.cliente = this.$store.state.contrato.cliente;
    this.$apollo.query({
      query: CLIENTE_EMAIL,
      variables: {
        email: this.$store.state.contrato.cliente.email
      }
    }).then(res => {
      if (res.data) {
        this.selectItem(res.data.clientePerEmail);
      } else {
        this.nombre = this.$store.state.contrato.cliente.nombre;
        this.apellidos = this.$store.state.contrato.cliente.apellidos;
        this.edad = this.$store.state.contrato.cliente.edad;
        this.email = this.$store.state.contrato.cliente.email;
        this.phone = this.$store.state.contrato.cliente.telefono;
      }
    });
    this.canal = this.$store.state.contrato.canal;
    this.salida = this.$store.state.contrato ? this.$store.state.contrato.fechaSalida : null;
    this.regreso = this.$store.state.contrato ? this.$store.state.contrato.fechaRegreso : null;
    this.dias = this.$store.state.contrato ? this.$store.state.contrato.dias : 0;
    if (this.$store.state.contrato.extraservicecontractSet.length > 0) {
      this.description_extra_service = this.$store.state.contrato.extraservicecontractSet[0].description;
      this.precio_otro = this.$store.state.contrato.extraservicecontractSet[0].price;
      this.diario_otro = this.$store.state.contrato.extraservicecontractSet[0].daily;
      this.Adicionar("otro", this.precio_otro, this.diario_otro, this.description_extra_service);
    }
    this.comentarios = this.$store.state.contrato ? this.$store.state.contrato.comentarios : "";
    this.cortesia = this.$store.state.contrato.cortesia ? this.$store.state.contrato.cortesia.id : 0;
    this.desc_reserva = this.cortesia !== 0;
    this.descuento_cortesia = this.$store.state.contrato.descuento;
    this.desc_original_contract = this.$store.state.contrato.descuento;
    this.tarifa_diaria = parseFloat(this.$store.state.contrato.tarifaDiaria).toFixed(2);
    this.tarifa_hora_extra = parseFloat(this.$store.state.contrato.precioHoraExtra).toFixed(2);
    this.tipo_pago = this.$store.state.contrato.tipoPago;
    this.monto_acreditado = parseFloat(parseFloat(this.$store.state.contrato.total) - parseFloat(this.$store.state.contrato.deuda) - parseFloat(this.$store.state.contrato.descuento) > 0 ? parseFloat(this.$store.state.contrato.total) - parseFloat(this.$store.state.contrato.deuda) - parseFloat(this.$store.state.contrato.descuento) : 0).toFixed(2);
    this.deposito = parseFloat(this.$store.state.contrato.depositoEfectivo).toFixed(2);
    this.check = parseFloat(this.$store.state.contrato.checkin).toFixed(2);
    this.tarjeta = this.$store.state.contrato.tarjeta ? this.$store.state.contrato.tarjeta.numero : "";
    this.tarjeta_autorizacion = this.$store.state.contrato.tarjeta ? this.$store.state.contrato.tarjeta.autorizacion : "";
    this.exp_tarjeta = this.$store.state.contrato.tarjeta ? this.$store.state.contrato.tarjeta.vencimiento : null;
    this.credito = this.$store.state.contrato.credito;
    this.pagar_diferencia = !this.$store.state.contrato.credito;
    this.contrato = this.$store.state.contrato;
    if (this.contrato.conductor !== null) {
      this.extra_nombre = this.contrato.conductor ? this.contrato.conductor.nombre : "";
      this.extra_apellidos = this.contrato.conductor ? this.contrato.conductor.apellidos : "";
      this.extra_edad = this.contrato.conductor ? this.contrato.conductor.edad : "";
      this.extra_licencia = this.contrato.conductor ? this.contrato.conductor.licencia.numero : "";
      this.extra_exp_licencia = this.contrato.conductor ? this.contrato.conductor.licencia.vencimiento : "";
      this.extra_estado = this.contrato.conductor ? this.contrato.conductor.licencia.estado : "";
      this.licencia_permanente_extra = this.contrato.conductor ? this.contrato.conductor.licencia.permanente : "";
      this.extra_pais = this.contrato.conductor ? this.contrato.conductor.licencia.pais : "";
    }
    servicesPerName().then(response => {
      this.serviciosPerName = response.data.data.serviciosPerName;
    });
    this.$apollo.query({
      query: GASOLINA
    }).then(response => {
      this.precio_gasolina = response.data.gasolina;
      this.tanque_lleno = this.$store.state.contrato.tanque;
    });
  },
  computed: {
    ...mapState(["temporada"]),
    ...mapState(["Cargando"]),
    ...mapGetters(["tienePermiso"]),
    elementosPaginaActual() {
      const inicio = (this.paginaActual - 1) * this.elementosPorPagina;
      const fin = inicio + this.elementosPorPagina;
      return this.serviciosPerName.slice(inicio, fin);
    }
  }
};
</script>

<style scoped>
.text-input-uv {
  color: rgba(128, 128, 128, 0.7) !important;
}

.input-container {
  position: relative;
}

.clear-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 60%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.btn-primary1 {
  background-color: #FFA633;
  border: 2px solid #FFA633 !important;
  box-shadow: inset 0 0 0 0 #fff;
  color: #fff;
}

.btn-primary1:hover {
  color: rgba(255, 255, 255, 0.8);
}

.btn-issy {
  background-color: #fff;
  border: 2px solid #FFA633 !important;
  color: #FFA633;
  box-shadow: inset 0 0 0 0 #fff;
}

.btn-issy:hover {
  color: rgba(255, 166, 51, 0.87);
}

.text-gray {
  color: #888888;
}

.modal-content1 {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 30%;
}

.services_extras_container {
  height: 400px;
  overflow-y: hidden;
}

.services_extras {
  height: 12%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 550px) {
  .modal-content1 {
    width: 80%;
  }

  .modal-content {
    width: 90%;
  }

  .services_extras_container {
    height: 500px;
    overflow-y: hidden;
  }

  .services_extras {
    height: 12%;
    display: block;
    justify-content: center;
    align-items: center;
  }
}
</style>
